import { translations } from '../_translations';

export function trim(value: string): string {
  return value.replace(/\s/g, '');
}

export function isEmptyString(value: string): boolean {
  return !value || trim(value) === '';
}

export type TValidatorResponse = {
  isValid: boolean;
  message: string;
};

export const formValidator = {
  email: function (email: string): TValidatorResponse {
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    return {
      isValid,
      message: isValid ? null : translations.getLabel('ERRORS.VALIDATION.INVALID'),
    };
  },
  matchingPasswords: function (newPassword: string, repeatNewPassword: string): TValidatorResponse {
    const isNewPasswordValid = formValidator.password(newPassword).isValid;
    const isRepeatNewPasswordValid = formValidator.password(repeatNewPassword).isValid;

    const isValid = isNewPasswordValid && isRepeatNewPasswordValid && newPassword === repeatNewPassword;
    return {
      isValid,
      message: isValid ? null : translations.getLabel('ERRORS.VALIDATION.INVALID_NEW_AND_REPEATED_PASSWORD'),
    };
  },
  minLength: function (value: string, min: number): TValidatorResponse {
    const isValid = `${value}`.length >= min;
    return {
      isValid,
      message: isValid ? null : translations.getLabel('ERRORS.VALIDATION.MIN_LENGTH', { length: min }),
    };
  },
  password: function (password: string): TValidatorResponse {
    // Password requirements: min. 8 characters, at least one uppercase letter, one lowercase letter, and one number.
    const length = 8;
    let isValid = formValidator.minLength(password, length).isValid;
    if (!isValid) {
      return {
        isValid,
        message: translations.getLabel('ERRORS.VALIDATION.PASSWORD_TOO_SHORT'),
      };
    }
    isValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password);
    return {
      isValid,
      message: isValid ? null : translations.getLabel('ERRORS.VALIDATION.PASSWORD_UNSAFE'),
    };
  },
};
