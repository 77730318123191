import { AuthAction, AuthActionType } from './actions';

export type AuthState = {
  accessToken?: string;
  isRegistered: boolean;
  requestError?: unknown;
  token?: string;
};

const initialState: AuthState = {
  isRegistered: false,
};

export default function reducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.CheckEpycToken:
      return {
        ...state,
        token: action.payload.token,
      };
    case AuthActionType.RequestAccessToken:
      return {
        ...state,
      };
    case AuthActionType.RequestAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case AuthActionType.RequestAccessTokenError:
      return {
        ...state,
        requestError: action.payload.error,
      };
    case AuthActionType.Register:
      return {
        ...state,
        isRegistered: false,
      };
    case AuthActionType.RegisterSuccess:
      return {
        ...state,
        isRegistered: true,
      };
    case AuthActionType.RegisterError:
      return {
        ...state,
        isRegistered: false,
      };
    case AuthActionType.SetError:
      return {
        ...state,
        requestError: action.payload.error,
      };
    case AuthActionType.ResetRegistration:
      return {
        ...state,
        isRegistered: false,
      };
    default:
      return state;
  }
}
