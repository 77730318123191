import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import EpycEntryPoint from './_routing/layouts/EpycEntryPoint';
import AppRoute from './_routing/routes/AppRoute';
import { chooseTypeActions } from './_store/actions';
import { authSelectors } from './_store/selectors';
import { isRenderedInIframe } from './_utils/questionHelpers';
import Login from './auth/Login';
import { examTypes } from './choose-type/_models/ExamType';
import ChooseExamType from './choose-type/ChooseExamType';
import Question from './examination/question/Question';
import ExampleQuestion from './example-question/ExampleQuestion';
import Publicity from './publicity/Publicity';
import Result from './result/Result';
import ResultQuestion from './result-question/ResultQuestion';
import StartScreen from './startscreen/StartScreen';

const App: FC = () => {
  const isEpyc = useSelector(authSelectors.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const skyScraperLeft = document.getElementById('div-gpt-ad-skyscraper-left');
    const skyScraperRight = document.getElementById('div-gpt-ad-skyscraper-right');
    const leaderboard = document.getElementById('div-gpt-ad-leaderboard');

    skyScraperLeft.classList.add('skyscraper-left');
    skyScraperRight.classList.add('skyscraper-right');
    leaderboard.classList.add('leaderboard');
  }, []);

  useEffect(() => {
    /*
      CookieBot script enabled for desktop & mobile web app
      when rendered in an iframe, we show a cookie sentence on de start page
    */
    if (!isRenderedInIframe()) {
      // script tag for cookiebot
      const cookie = document.createElement('script');
      cookie.src = 'https://consent.cookiebot.com/uc.js';
      cookie.type = 'text/javascript';
      cookie.id = 'Cookiebot';
      cookie.setAttribute('data-blockingmode', 'auto');
      cookie.setAttribute('data-culture', 'nl');
      cookie.setAttribute('data-cbid', 'b7c227c2-61c1-4630-9714-df673e14cc68');
      document.body.appendChild(cookie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /*
      CookieDeclaration script enabled for desktop & mobile web app
    */
    if (!isRenderedInIframe()) {
      // script tag for declaration
      const declaration = document.createElement('script');
      declaration.id = 'CookieDeclaration';
      declaration.src = 'https://consent.cookiebot.com/b7c227c2-61c1-4630-9714-df673e14cc68/cd.js';
      declaration.type = 'text/javascript';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /*
      Auto-select 'B' as examType when using the Epyc entrypoint
    */
    if (!isEpyc) return;
    dispatch(new chooseTypeActions.SelectExamType({ type: 'B' }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEpyc]);

  const baseRoute = `/:type(${examTypes.join('|')})`;
  return (
    <>
      <Switch>
        {/** Entrypoint for epyc */}
        <AppRoute component={EpycEntryPoint} path="/rijbewijsB/epyc/:token?" />

        {/** Unauthorized routes */}
        <AppRoute component={ChooseExamType} exact path="/type" />
        <AppRoute component={Login} exact path={`${baseRoute}/login`} showFooter={false} showHeader={false} />

        {/** Authorized routes */}
        <AppRoute component={StartScreen} exact isAuthorizedRoute path={`${baseRoute}/start`} showFooter={false} />
        <AppRoute component={ExampleQuestion} exact isAuthorizedRoute path={`${baseRoute}/example-question`} />
        <AppRoute component={Question} exact isAuthorizedRoute path={`${baseRoute}/examination`} />
        <AppRoute component={Publicity} exact isAuthorizedRoute path={`${baseRoute}/publicity`} />
        <AppRoute component={Result} exact isAuthorizedRoute path={`${baseRoute}/result`} />
        <AppRoute component={ResultQuestion} isAuthorizedRoute path={`${baseRoute}/result-questions`} />

        {/** Redirects */}
        <Redirect exact from={baseRoute} to={`${baseRoute}/start`} />
        <Redirect to="/type" />
      </Switch>
    </>
  );
};

export default App;
