import publicityImageAam from '../_assets/images/publicity_aam.jpg';
import publicityImageB from '../_assets/images/publicity_b.jpg';
import publicityImageBike from '../_assets/images/publicity_bike.jpg';
import { translations } from '../_translations';
import { ExamType } from '../choose-type/_models/ExamType';
import { AamContent, BContent, BikeContent } from '../publicity/PublicityContent';

type TStartScreenInfo = Record<
  ExamType,
  {
    literals: string[];
    type: string;
  }
>;

type TPublicityInfo = Record<
  ExamType,
  {
    Content: React.ComponentType;
    button_text: string;
    image: string;
    link: string;
  }
>;

export const startScreenInfo: TStartScreenInfo = {
  'A-AM': {
    literals: ['ONE', 'TWO', 'THREE'],
    type: 'AAM',
  },
  B: {
    literals: ['ONE', 'TWO', 'THREE', 'FOUR'],
    type: 'B',
  },
  fiets: {
    literals: ['ONE', 'TWO', 'THREE'],
    type: 'BIKE',
  },
};

export const publicityInfo: TPublicityInfo = {
  'A-AM': {
    Content: AamContent,
    button_text: translations.getLabel('PUBLICITY.AAM.BUTTON_TEXT'),
    image: publicityImageAam,
    link: 'https://www.vab.be/nl/rijschool/rijbewijzen-en-opleidingen/motor/rijbewijs-am',
  },
  B: {
    Content: BContent,
    button_text: translations.getLabel('PUBLICITY.B.BUTTON_TEXT'),
    image: publicityImageB,
    link: 'https://www.vab.be/nl/rijschool/online-theorie',
  },
  fiets: {
    Content: BikeContent,
    button_text: translations.getLabel('PUBLICITY.BIKE.BUTTON_TEXT'),
    image: publicityImageBike,
    link: 'https://www.vab.be/nl/rijschool/sustainable-driving/elektrisch-fietsen',
  },
};
