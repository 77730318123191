import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { chooseTypeActions } from '../../../_store/actions';
import { chooseTypeSelectors } from '../../../_store/selectors';
import { Language } from '../../../_translations/Language';
import { ExamType } from '../../../choose-type/_models/ExamType';
import ExaminationLayoutMenu from '../examinationlayout-menu/ExaminationLayoutMenu';

import '../layout.scss';

const GeneralLayout = ({ children, showHeader = true, showFooter = true }) => {
  const locale = Language.getLocale() || 'nl';
  const dispatch = useDispatch();
  const storeType = useSelector(chooseTypeSelectors.examType);
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    if (storeType === type) return;
    if (!type) return;

    dispatch(new chooseTypeActions.SelectExamType({ type: type as ExamType }));
  }, [type, storeType]);

  return (
    <div className="layout">
      {showHeader && <ExaminationLayoutMenu />}
      {children}
      {showFooter && (
        <div className="disclaimer">
          © {new Date().getFullYear()}
          <>
            <a href={`https://www.vab.be/${locale}/rijschool`} rel="noopener noreferrer" target="_blank">
              VAB Rijschool nv
            </a>
            - Alle rechten voorbehouden -
            <a href={`https://www.vab.be/${locale}/disclaimer/`} rel="noopener noreferrer" target="_blank">
              Disclaimer
            </a>
            -
            <a href={`https://www.vab.be/${locale}/privacy/`} rel="noopener noreferrer" target="_blank">
              Privacy
            </a>
          </>
          -
          <a href="mailto: proefexamen@vabrijschool.be" rel="noopener noreferrer" target="_blank">
            Contact
          </a>
        </div>
      )}
    </div>
  );
};

export default GeneralLayout;
