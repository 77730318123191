import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { authSelectors } from '../../_store/selectors';
import { config } from '../../config';
import GeneralLayout from '../layouts/general/GeneralLayout';

const AuthorizedRoute = ({ component: Component }) => {
  const isRegistered = useSelector(authSelectors.isRegistered);
  const token = useSelector(authSelectors.token);
  const isLoggedIn = config.isLoggedInForDev() || isRegistered || token;

  if (!isLoggedIn) {
    return <Redirect to="login" />;
  }

  return <Component />;
};

const AppRoute = ({ component: Component, showFooter = true, showHeader = true, isAuthorizedRoute = false, ...rest }) => {
  return (
    <Route {...rest}>
      <GeneralLayout showFooter={showFooter} showHeader={showHeader}>
        {isAuthorizedRoute ? <AuthorizedRoute component={Component} /> : <Component />}
      </GeneralLayout>
    </Route>
  );
};

export default AppRoute;
