import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Redirect, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { useToggle } from '../../_hooks';
import { Button, Timer } from '../../_shared';
import ImageWithFallback from '../../_shared/ImageWithFallback';
import { examinationActions } from '../../_store/actions';
import { authSelectors, examinationSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { AnswerOptionStatus, isEpyc, shuffle } from '../../_utils/questionHelpers';
import { Answer } from '../_models';
import AnswerOption from '../answer-option/AnswerOption';
import ImageCloseUp from '../modal/imgcloseup/ImageCloseUp';
import StopExaminationModal from '../modal/stopexaminationmodal/StopExaminationModal';

const Question: FC = () => {
  const [currentAnswers, setCurrentAnswers] = useState<Answer[]>([]);
  const { pathname } = useLocation();
  const questions = useSelector(examinationSelectors.questions) || [];
  const currentQuestion = useSelector(examinationSelectors.currentQuestion);
  const amountOfQuestions = useSelector(examinationSelectors.amountOfQuestions);
  const isLastQuestion = useSelector(examinationSelectors.isLastQuestion(amountOfQuestions));
  const currentQuestionIndex = questions.indexOf(currentQuestion);
  const selectedAnswer = useSelector(examinationSelectors.answerForQuestion(currentQuestion?.question_id));
  const token = useSelector(authSelectors.token);
  const [isImageCloseOpen, showImageCloseUp] = useToggle(false);
  const [isStopExaminationOpen, setStopExamination] = useToggle(false);
  const dispatch = useDispatch();
  const shouldShowPrompt = !isStopExaminationOpen && !isLastQuestion && pathname === '/examination';

  if (!questions.length || !currentQuestion) return <Redirect to="/" />;

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentAnswers(shuffle(currentQuestion?.answers));
  }, [currentQuestion, currentQuestionIndex]);

  function goToNextQuestion(): void {
    if (isLastQuestion) dispatch(new examinationActions.ShowResult());
    else dispatch(new examinationActions.SelectNextQuestion());
  }

  function answerToStatus(answer: Answer): AnswerOptionStatus {
    if (!selectedAnswer) return AnswerOptionStatus.Unselected;
    if (selectedAnswer.answer_id === answer.answer_id) return AnswerOptionStatus.Selected;
    return AnswerOptionStatus.Unselected;
  }

  return (
    <>
      {isStopExaminationOpen && <StopExaminationModal closeModal={() => setStopExamination(false)} />}
      {isImageCloseOpen && <ImageCloseUp onClose={() => showImageCloseUp(false)} question={currentQuestion} />}
      <Timer question={currentQuestion} />
      <Prompt message={translations.getLabel('QUESTIONS.PROMPT')} when={shouldShowPrompt} />
      <div className={classnames('question-wrapper', { 'is-epyc': isEpyc(token) })}>
        <div className="pinch-zoom-wrapper">
          <ImageWithFallback
            alt={translations.getLabel('MODAL.IMAGE_ALT')}
            onClick={() => showImageCloseUp(true)}
            src={currentQuestion.img_url}
          />
        </div>
        <div className="answers-wrapper">
          <p className="info">{`${translations.getLabel('QUESTIONS.QUESTION').toUpperCase()} ${
            currentQuestionIndex + 1
          }/${amountOfQuestions}`}</p>
          <h2>{currentQuestion.question}</h2>
          {currentAnswers.map(answer => (
            <AnswerOption
              answer={answer}
              key={answer.answer_id}
              onSelect={() =>
                dispatch(
                  new examinationActions.SetAnswer({
                    answer: answer,
                    questionId: currentQuestion?.question_id,
                  }),
                )
              }
              status={answerToStatus(answer)}
            />
          ))}
          <div className="actions-wrapper">
            <Button asText onClick={() => setStopExamination(true)}>
              {translations.getLabel('BUTTONS.STOP')}
            </Button>
            <Button onClick={goToNextQuestion} theme={isLastQuestion ? 'primary' : 'neutral'}>
              {translations.getLabel(isLastQuestion ? 'BUTTONS.SHOW_RESULTS' : 'BUTTONS.NEXT')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
