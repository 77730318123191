import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from 'connected-react-router';

import { configureStore, history } from './_store/store';
import App from './App';
import { config } from './config';
import * as serviceWorker from './serviceWorker';

import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = config.sentryDsn() && environmentsWithErrorLogging.includes(config.environment());
if (needsErrorLogging) {
  Sentry.init({
    dsn: config.sentryDsn(),
    environment: config.environment(),
  });
}

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HashRouter>
        <App />
      </HashRouter>
    </ConnectedRouter>
  </Provider>,
);

serviceWorker.unregister();
