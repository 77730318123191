import { Action } from 'redux';

import { ExamType } from '../_models/ExamType';

export enum ChooseTypeActionType {
  SelectExamType = '[ExamType] SelectExamType',
}
export class SelectExamType implements Action<ChooseTypeActionType> {
  readonly type = ChooseTypeActionType.SelectExamType;
  constructor(public payload: { type: ExamType }) {}
}

export type ChooseTypeAction = SelectExamType;
