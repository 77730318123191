import { push } from 'connected-react-router';
import { Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { chooseTypeSelectors } from '../../_store/selectors';
import { ChooseTypeActionType } from './actions';

const selectExamTypeEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ChooseTypeActionType.SelectExamType),
    map(() => push(`/${chooseTypeSelectors.examType(state$.value)}/login`)),
  );

export default [selectExamTypeEpic$];
