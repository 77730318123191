import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import logo from '../../../_assets/images/logo.svg';
import { authSelectors } from '../../../_store/selectors';

import './examinationlayoutMenu.scss';

const ExaminationLayoutMenu: FC = () => {
  const { pathname } = useLocation();
  const isResultQuestion = pathname === '/result-questions';
  const isStartScreen = pathname === '/start';
  const isEpyc = useSelector(authSelectors.token);

  return (
    <header className={classnames('', { 'is-epyc': !isStartScreen || isEpyc })}>
      {!isResultQuestion && (
        <>
          <a href="https://www.vab.be/nl/rijschool" rel="noopener noreferrer" target="_blank">
            <img alt="vab-logo" src={logo} />
          </a>
          <div className="timer" />
        </>
      )}
    </header>
  );
};

export default ExaminationLayoutMenu;
