import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useToggle } from '../_hooks';
import { Button, Icon } from '../_shared';
import Checkbox from '../_shared/checkbox/Checkbox';
import ErrorMessage from '../_shared/errorMessage/ErrorMessage';
import InputField from '../_shared/inputField/InputField';
import { authActions } from '../_store/actions';
import { authSelectors, chooseTypeSelectors, examinationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { startScreenInfo } from '../_utils/examHelpers';
import { formValidator } from '../_utils/formValidation';
import { isRenderedInIframe } from '../_utils/questionHelpers';
import { Auth } from './_models/Auth';

import './login.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [optIn, setOptIn] = useToggle(false);
  const requestError = useSelector(authSelectors.requestError);
  const examType = useSelector(chooseTypeSelectors.examType);
  const dispatch = useDispatch();
  const examinationError = useSelector(examinationSelectors.error);

  function registerUser() {
    if (!formValidator.email(email).isValid) {
      dispatch(new authActions.SetError({ error: translations.getLabel('LOGIN.ERROR') }));
      return;
    }
    const body: Auth = {
      email,
      optIn,
      origin: examType,
    };
    dispatch(new authActions.RequestAccessToken({ body }));
  }

  const { type } = startScreenInfo[examType] || {};

  if (!type) return <Redirect to="/" />;

  return (
    <div className="login-wrapper">
      <span className="title-wrapper">
        <h1>{translations.getLabel(`LOGIN.TITLE_ONE.${type}`)}</h1>
        <h1>{translations.getLabel('LOGIN.TITLE_TWO')}</h1>
      </span>
      <h3>{translations.getLabel('LOGIN.LOGIN')}</h3>
      <div className="form">
        <InputField
          autoComplete="email"
          className="email"
          name="email"
          onChange={value => setEmail(value as string)}
          placeholder={translations.getLabel('LOGIN.MAIL')}
          type="email"
          value={email}
        />
        <Checkbox
          checked={optIn}
          className="opt-in"
          label={translations.getLabel('LOGIN.OPTIN')}
          name="opt-in"
          onChange={setOptIn}
        />
        <ErrorMessage isVisible={!!requestError}>{translations.getLabel('LOGIN.ERROR')}</ErrorMessage>
        <ErrorMessage isVisible={!!examinationError}>{translations.getLabel('START.ERROR')}</ErrorMessage>
        <Button className="start-examination" disabled={!email} onClick={registerUser} theme="primary" type="submit">
          <p>{translations.getLabel('BUTTONS.START')}</p>
          <Icon name="SvgChevronRight" />
        </Button>
      </div>
      {isRenderedInIframe() && (
        <div className="cookie__dark" dangerouslySetInnerHTML={{ __html: translations.getLabel('LOGIN.COOKIE') }} />
      )}
      <div className="mail-to" dangerouslySetInnerHTML={{ __html: translations.getLabel('LOGIN.QUESTIONS') }} />
    </div>
  );
};

export default Login;
