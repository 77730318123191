import { push } from 'connected-react-router';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { authActions, examinationActions } from '../../_store/actions';
import { chooseTypeSelectors } from '../../_store/selectors';
import { AccessTokenResponse } from '../_models/AccessTokenResponse';
import { AuthActionType } from './actions';
import * as authApi from './api';

export const requestAccessTokenEpic$: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionType.RequestAccessToken),
    exhaustMap(({ payload }: authActions.RequestAccessToken) =>
      from(authApi.requestAccessToken()).pipe(
        map(
          (response: AccessTokenResponse) =>
            new authActions.RequestAccessTokenSuccess({
              accessToken: response.Message,
              body: payload.body,
            }),
        ),
        catchError(error => of(new authActions.RequestAccessTokenError({ error }))),
      ),
    ),
  );

export const requestAccessTokenSuccessEpic$: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionType.RequestAccessTokenSuccess),
    exhaustMap(({ payload }: authActions.RequestAccessTokenSuccess) =>
      from(authApi.register(payload.body, payload.accessToken)).pipe(
        map(() => new authActions.RegisterSuccess()),
        catchError(error => of(new authActions.RegisterError({ error }))),
      ),
    ),
  );

export const checkEpycTokenEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(AuthActionType.CheckEpycToken),
    exhaustMap(({ payload }: authActions.CheckEpycToken) =>
      from(authApi.checkEpycToken(payload.token)).pipe(
        map((response: string) => {
          if (!response) of(push('/B/login'));

          return new authActions.RequestAccessToken({
            body: {
              email: response,
              optIn: false,
              origin: chooseTypeSelectors.examType(state$.value),
            },
          });
        }),
        catchError(() => of(push('/B/login'))),
      ),
    ),
  );

const registerSuccessEpic$: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionType.RegisterSuccess),
    map(() => new examinationActions.FetchExamination()),
  );

export default [requestAccessTokenEpic$, requestAccessTokenSuccessEpic$, registerSuccessEpic$, checkEpycTokenEpic$];
