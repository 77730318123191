import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button, Icon } from '../_shared';
import ImageWithFallback from '../_shared/ImageWithFallback';
import { examinationActions } from '../_store/actions';
import { chooseTypeSelectors, examinationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { AnswerOptionStatus, isMajorMistake } from '../_utils/questionHelpers';
import { Answer } from '../examination/_models';
import AnswerOption from '../examination/answer-option/AnswerOption';

import './resultquestion.scss';

const ResultQuestion: FC = () => {
  const currentQuestion = useSelector(examinationSelectors.currentQuestion);
  const result = useSelector(examinationSelectors.result);
  const currentQuestionIndex = useSelector(examinationSelectors.currentQuestionIndex);
  const amountOfQuestions = useSelector(examinationSelectors.amountOfQuestions);
  const examType = useSelector(chooseTypeSelectors.examType);
  const givenAnswer = result[currentQuestion?.question_id];
  const firstQuestion = currentQuestionIndex === 0;
  const dispatch = useDispatch();
  const isLastQuestion = useSelector(examinationSelectors.isLastQuestion(amountOfQuestions));

  function answerToStatus(answer: Answer): AnswerOptionStatus {
    if (answer.correct_ind) return AnswerOptionStatus.Correct;
    if (answer.answer_id === givenAnswer?.answer_id && !givenAnswer?.correct_ind) return AnswerOptionStatus.Wrong;
    return AnswerOptionStatus.Unselected;
  }

  function showNextResultQuestion(): void {
    dispatch(new examinationActions.ShowNextResultQuestion());
  }

  if (!currentQuestion) return <Redirect to="/result" />;

  return (
    <div className="question-wrapper result-question">
      <div className="top">
        <div className="pinch-zoom-wrapper">
          <ImageWithFallback alt={translations.getLabel('MODAL.IMAGE_ALT')} src={currentQuestion.img_url} />
        </div>
        <div className="answers-wrapper">
          <p className="info">{`${translations.getLabel('QUESTIONS.QUESTION').toUpperCase()} ${
            currentQuestionIndex + 1
          }/${amountOfQuestions}`}</p>
          <h2>{currentQuestion.question}</h2>
          {currentQuestion.answers.map(answer => (
            <AnswerOption answer={answer} key={answer.answer_id} status={answerToStatus(answer)} />
          ))}
          {currentQuestion.explanation && (
            <>
              <h5>
                {translations.getLabel('QUESTIONS.EXPLANATION')}
                {isMajorMistake(currentQuestion) && (
                  <span> - {translations.getLabel('QUESTIONS.MAJOR_MISTAKE').toLowerCase()}</span>
                )}
              </h5>
              <p className="explanation">{currentQuestion.explanation}</p>
            </>
          )}
        </div>
      </div>
      <div className="buttons-wrapper">
        <Icon
          className="arrow left"
          disabled={firstQuestion}
          name="SvgArrow"
          onClick={() => dispatch(new examinationActions.ShowPreviousResultQuestion())}
        />
        <Button disabled={isLastQuestion} href={`/${examType}/result`}>
          {translations.getLabel('BUTTONS.BACK_TO_OVERVIEW')}
        </Button>
        <Icon className="arrow right" disabled={isLastQuestion} name="SvgArrow" onClick={showNextResultQuestion} />
      </div>
    </div>
  );
};

export default ResultQuestion;
