import React, { FC } from 'react';

import { Button, Icon } from '../../../_shared';
import { translations } from '../../../_translations';

import './outoftime.scss';

type Props = {
  onClose: () => void;
};

const OutOfTime: FC<Props> = ({ onClose }) => {
  return (
    <div className="outoftime-modal">
      <Icon className="close-icon" name="SvgClose" onClick={onClose} />
      <div className="info-wrapper">
        <h2>{translations.getLabel('MODAL.OUT_OF_TIME.TITLE')}</h2>
        <p>{translations.getLabel('MODAL.OUT_OF_TIME.INFO_ONE')}</p>
        <p>{translations.getLabel('MODAL.OUT_OF_TIME.INFO_TWO')}</p>
        <p>{translations.getLabel('MODAL.OUT_OF_TIME.INFO_THREE')}</p>
      </div>
      <Button className="close reversed" onClick={onClose}>
        {translations.getLabel('BUTTONS.CLOSE')}
      </Button>
    </div>
  );
};

export default OutOfTime;
