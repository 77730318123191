import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, filter, flatMap, map, takeUntil } from 'rxjs/operators';

import { examinationActions } from '../../_store/actions';
import { chooseTypeSelectors, examinationSelectors } from '../../_store/selectors';
import { RENDER_DELAY } from '../../_utils/questionHelpers';
import { Examination } from '../_models';
import { ExaminationActionType } from './actions';
import * as ExaminationApi from './api';

const selectNextQuestionEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.SelectNextQuestion),
    flatMap(() => {
      const question = examinationSelectors.currentQuestion(state$.value);
      const amountOfQuestions = examinationSelectors.amountOfQuestions(state$.value);
      return from(new Promise(resolve => setTimeout(() => resolve(true), question?.time * 1000 * RENDER_DELAY))).pipe(
        // Cancel any following behavior in this epic if ShowResult is dispatched before timeout promise has resolved
        takeUntil(action$.pipe(ofType(ExaminationActionType.ShowResult))),

        filter(() => {
          // Do nothing if question changed
          const currentQuestion = examinationSelectors.currentQuestion(state$.value);
          return currentQuestion === question;
        }),

        map(() => {
          if (examinationSelectors.isLastQuestion(amountOfQuestions)(state$.value)) return new examinationActions.ShowResult();
          return new examinationActions.SelectNextQuestion();
        }),
      );
    }),
  );

const showResultEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.ShowResult),
    map(() => push(`/${state$.value.type.examType}/result`)),
  );

const startExaminationEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.StartExamination),
    exhaustMap(() => of(new examinationActions.SelectNextQuestion(), push(`/${state$.value.type.examType}/examination`))),
  );

const fetchExaminationEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.FetchExamination),
    exhaustMap(() =>
      from(ExaminationApi.fetchExercises(chooseTypeSelectors.examType(state$.value))).pipe(
        map((response: AxiosResponse<Examination>) => {
          return new examinationActions.FetchExaminationSuccess({
            examination: response.data,
          });
        }),
        catchError(error => of(new examinationActions.FetchExaminationError({ error }))),
      ),
    ),
  );

const fetchExaminationSuccess$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.FetchExaminationSuccess),
    map(() => push(`/${chooseTypeSelectors.examType(state$.value)}/start`)),
  );

const startExampleQuestionEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ExaminationActionType.StartExampleQuestion),
    map(() => push(`/${state$.value.type.examType}/example-question`)),
  );

const showResultQuestion$: Epic = action$ =>
  action$.pipe(
    ofType(ExaminationActionType.ShowResultQuestion),
    map(() => push('result-questions')),
  );

export default [
  selectNextQuestionEpic$,
  startExaminationEpic$,
  showResultQuestion$,
  showResultEpic$,
  fetchExaminationEpic$,
  fetchExaminationSuccess$,
  startExampleQuestionEpic$,
];
