import React from 'react';

import { translations } from '../_translations';

export const BContent = () => {
  return (
    <>
      <h2>{translations.getLabel('PUBLICITY.B.TITLE')}</h2>

      <p>{translations.getLabel('PUBLICITY.B.INFO_ONE')}</p>
      <p>{translations.getLabel('PUBLICITY.B.INFO_TWO')}</p>

      <ul>
        <li>{translations.getLabel('PUBLICITY.B.LIST_ONE')}</li>
        <li>{translations.getLabel('PUBLICITY.B.LIST_TWO')}</li>
        <li>{translations.getLabel('PUBLICITY.B.LIST_THREE')}</li>
        <li>{translations.getLabel('PUBLICITY.B.LIST_FOUR')}</li>
      </ul>
    </>
  );
};

export const AamContent = () => {
  return (
    <>
      <h2>{translations.getLabel('PUBLICITY.AAM.TITLE')}</h2>

      <p dangerouslySetInnerHTML={{ __html: translations.getLabel('PUBLICITY.AAM.INFO_ONE') }} />
      <br />
      <p dangerouslySetInnerHTML={{ __html: translations.getLabel('PUBLICITY.AAM.INFO_TWO') }} />
      <br />
    </>
  );
};

export const BikeContent = () => {
  return (
    <>
      <h2>{translations.getLabel('PUBLICITY.BIKE.TITLE')}</h2>

      <p>{translations.getLabel('PUBLICITY.BIKE.INFO_ONE')}</p>
      <br />
      <p>{translations.getLabel('PUBLICITY.BIKE.INFO_TWO')}</p>
      <br />

      <p>{translations.getLabel('PUBLICITY.BIKE.INFO_THREE')}</p>
    </>
  );
};
