import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { getAmountOfQuestions } from '../../_utils/questionHelpers';
import { ExaminationState } from './reducer';

const selectNode = (state: AppState) => state.examination;

export const exampleQuestion = createSelector(selectNode, (state: ExaminationState) => state.exampleQuestion);
export const currentQuestion = createSelector(selectNode, (state: ExaminationState) => state.currentQuestion);
export const amountOfQuestions = createSelector(selectNode, (state: ExaminationState) =>
  getAmountOfQuestions(state.data?.categories),
);
export const amountOfCorrectAnswersRequired = createSelector(
  selectNode,
  (state: ExaminationState) => parseInt(state.data?.correct_answers) || 41,
);
export const isLastQuestion = (amountOfQuestions: number) =>
  createSelector(
    selectNode,
    (state: ExaminationState) => state.questions.indexOf(state.currentQuestion) === amountOfQuestions - 1,
  );
export const answerForQuestion = (questionId: string) =>
  createSelector(selectNode, (state: ExaminationState) => state.result && state.result[questionId]);
export const currentQuestionIndex = createSelector(selectNode, (state: ExaminationState) =>
  state.questions.indexOf(state.currentQuestion),
);
export const result = createSelector(selectNode, (state: ExaminationState) => state.result);
export const questions = createSelector(selectNode, (state: ExaminationState) => state.questions);
export const error = createSelector(selectNode, (state: ExaminationState) => state.error);
export const data = createSelector(selectNode, (state: ExaminationState) => state.data);
