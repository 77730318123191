import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../_shared';
import { chooseTypeSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { publicityInfo } from '../_utils/examHelpers';

import './publicity.scss';

const Publicity = () => {
  const examType = useSelector(chooseTypeSelectors.examType);
  const { image, link, Content, button_text } = publicityInfo[examType] || {};

  return (
    <div className="publicity-wrapper">
      <img alt={translations.getLabel('PUBLICITY.IMG_ALT')} className="img" src={image} />
      <div className="info-wrapper">
        {<Content />}
        <a className="continue" href={link} rel="noopener noreferrer" target="_blank">
          {button_text}
        </a>
        <Button className="reversed continue" href={`/${examType}/login`}>
          {translations.getLabel('BUTTONS.AGAIN')}
        </Button>
      </div>
    </div>
  );
};

export default Publicity;
