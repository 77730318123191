import React, { FC } from 'react';
import classnames from 'classnames';

import * as ICONS from '../../_assets/svg';

import './icon.scss';

export type TIconType = keyof typeof ICONS;

type Props = {
  className?: string;
  clickable?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  name: TIconType;
  onClick?: (value: React.MouseEvent) => void;
  size?: number;
};

function shouldUseSvgColor(name: TIconType): boolean {
  const icons: TIconType[] = ['SvgChecked', 'SvgUnChecked', 'SvgArrow'];
  return !icons.includes(name);
}

const Icon: FC<Props> = ({ size = 2, name, onClick, disabled, className = '', label, clickable, id, ...otherProps }) => {
  const Svg = ICONS[name];
  if (!Svg) return null;

  const sizeObject = size ? { height: `${size}rem`, width: `${size}rem` } : {};
  const IconComponent = (
    <i {...otherProps} className={classnames('icon', { disabled, fill: shouldUseSvgColor(name) }, className)} style={sizeObject}>
      <Svg {...sizeObject} />
    </i>
  );
  if (!clickable && !onClick) return IconComponent;
  return (
    <button
      aria-label={label}
      className={classnames('plain', className)}
      disabled={disabled}
      id={id}
      onClick={value => onClick?.(value)}
      style={sizeObject}
      type="button"
    >
      {IconComponent}
    </button>
  );
};

export default Icon;
