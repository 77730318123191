import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';

import failedImage from '../_assets/images/failed.svg';
import passedImage from '../_assets/images/passed.svg';
import { Button } from '../_shared';
import { examinationActions } from '../_store/actions';
import { authSelectors, chooseTypeSelectors, examinationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { Language } from '../_translations/Language';
import { amountOfCorrectAnswers, hasPassedExamination, isEpyc, isMajorMistake } from '../_utils/questionHelpers';
import { Question } from '../examination/_models';

import './result.scss';

const Result: FC = () => {
  const result = useSelector(examinationSelectors.result);
  const questions = useSelector(examinationSelectors.questions) || [];
  const amountOfQuestions = useSelector(examinationSelectors.amountOfQuestions);
  const amountOfCorrectAnswersRequired = useSelector(examinationSelectors.amountOfCorrectAnswersRequired);
  const examType = useSelector(chooseTypeSelectors.examType);
  const token = useSelector(authSelectors.token);
  const locale = Language.getLocale();

  const correctAmountOfAnswers = amountOfCorrectAnswers(result);
  const isPassed = hasPassedExamination(result, amountOfCorrectAnswersRequired);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!Object.keys(result || {}).length || !questions.length) return <Redirect to="/" />;

  return (
    <>
      <div className={classnames('result-wrapper', { 'is-epyc': isEpyc(token) })}>
        <div className={classnames('img-wrapper', { isWrong: !isPassed })}>
          <div className="info">
            <h3>{`${correctAmountOfAnswers}/${amountOfQuestions}`}</h3>
            <p>{translations.getLabel(isPassed ? 'RESULT.PASSED' : 'RESULT.FAILED')}</p>
          </div>
          <img alt={translations.getLabel('RESULT.ALT')} src={isPassed ? passedImage : failedImage} />
        </div>
        <div className="results">
          <h2>{translations.getLabel('RESULT.TITLE')}</h2>
          <div className="questions-wrapper">
            {questions.map((question: Question, index: number) => {
              const givenAnswer = result[question.question_id];
              const isWrong = !givenAnswer || !givenAnswer.correct_ind;
              return (
                <p
                  className={classnames('question', {
                    isMajorMistake: isWrong && isMajorMistake(question),
                    isWrong,
                  })}
                  key={question.question_id}
                  onClick={() => dispatch(new examinationActions.ShowResultQuestion({ question }))}
                >
                  {index + 1}
                </p>
              );
            })}
          </div>
          <p>{translations.getLabel('RESULT.INFO')}</p>
          <Button className="continue" href={`/${examType}/publicity?language=${locale}`}>
            {translations.getLabel('BUTTONS.CONTINUE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Result;
