import { ExamType } from '../_models/ExamType';
import { ChooseTypeAction, ChooseTypeActionType } from './actions';

export type ChooseTypeState = {
  examType?: ExamType;
};

const initialState: ChooseTypeState = {};

export default function reducer(state = initialState, action: ChooseTypeAction): ChooseTypeState {
  switch (action.type) {
    case ChooseTypeActionType.SelectExamType:
      return {
        ...state,
        examType: action.payload.type,
      };
    default:
      return state;
  }
}
