import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../_shared';
import { authActions, chooseTypeActions } from '../_store/actions';
import { chooseTypeSelectors } from '../_store/selectors';
import { translations } from '../_translations';

import './chooseExamType.scss';

const examTypes = [
  { iconName: 'SvgLicenseB', name: 'Auto', text: translations.getLabel('TYPE.LICENSEB'), type: 'B' },
  {
    iconName: 'SvgLicenseAam',
    name: 'Motor, Bromfiets & Speed Pedelec',
    text: translations.getLabel('TYPE.LICENSEAAM'),
    type: 'A-AM',
  },
  { iconName: 'SvgLicenseBike', name: 'Fiets', text: translations.getLabel('TYPE.LICENSEBIKE'), type: 'fiets' },
];

const ChooseExamType = () => {
  return (
    <div className="choose-type-page-wrapper">
      <span className="title-wrapper">
        <h1>{translations.getLabel('TYPE.TITLE')}</h1>
      </span>
      <span>
        <p className="call-to-action">{translations.getLabel('TYPE.CTA')}</p>
      </span>
      <div className="types-container">
        {examTypes.map(type => (
          <ExamTypeCard {...type} key={type.name} />
        ))}
      </div>
    </div>
  );
};

export default ChooseExamType;

const ExamTypeCard = ({ name, text, iconName, type }) => {
  const examType = useSelector(chooseTypeSelectors.examType);
  const dispatch = useDispatch();

  const chooseType = () => {
    if (examType !== type) {
      dispatch(new authActions.ResetRegistration());
    }
    dispatch(new chooseTypeActions.SelectExamType({ type }));
  };

  return (
    <div className="card" onClick={chooseType}>
      <Icon name={iconName} size={8} />
      <div className="card-text-wrapper">
        <p className="card-text-name">{name}</p>
        <p className="card-text-text">
          {text} <Icon name="SvgChevronRight" />
        </p>
      </div>
    </div>
  );
};
