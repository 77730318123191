import React from 'react';

import logo from '../_assets/images/logo.svg';

type Props = {
  alt: string;
  fallBackSrc?: string;
  onClick?: () => void;
  src: string;
};

const ImageWithFallback = ({ src, alt, fallBackSrc = logo, onClick }: Props) => {
  const onError = e => (e.currentTarget.src = fallBackSrc);

  return <img alt={alt} onClick={onClick} onError={onError} src={src} />;
};

export default ImageWithFallback;
