import { Action } from 'redux';

import { Auth } from '../_models/Auth';

export enum AuthActionType {
  CheckEpycToken = '[Auth] CheckEpycToken',
  Register = '[Auth] Register',
  RegisterError = '[Auth] RegisterError',
  RegisterSuccess = '[Auth] RegisterSuccess',
  RequestAccessToken = '[Auth] RequestAccessToken',
  RequestAccessTokenError = '[Auth] RequestAccessTokenError',
  RequestAccessTokenSuccess = '[Auth] RequestAccessTokenSuccess',
  ResetRegistration = '[Auth] ResetRegistration',
  SetError = '[Auth] SetError',
}

export class CheckEpycToken implements Action<AuthActionType> {
  readonly type = AuthActionType.CheckEpycToken;
  constructor(public payload: { token: string }) {}
}
export class RequestAccessToken implements Action<AuthActionType> {
  readonly type = AuthActionType.RequestAccessToken;
  constructor(public payload: { body: Auth }) {}
}

export class RequestAccessTokenSuccess implements Action<AuthActionType> {
  readonly type = AuthActionType.RequestAccessTokenSuccess;
  constructor(public payload: { accessToken: string; body: Auth }) {}
}

export class RequestAccessTokenError implements Action<AuthActionType> {
  readonly type = AuthActionType.RequestAccessTokenError;
  constructor(public payload: { error: unknown }) {}
}

export class Register implements Action<AuthActionType> {
  readonly type = AuthActionType.Register;
}

export class RegisterSuccess implements Action<AuthActionType> {
  readonly type = AuthActionType.RegisterSuccess;
}

export class RegisterError implements Action<AuthActionType> {
  readonly type = AuthActionType.RegisterError;
  constructor(public payload: { error: unknown }) {}
}

export class ResetRegistration implements Action<AuthActionType> {
  readonly type = AuthActionType.ResetRegistration;
}

export class SetError implements Action<AuthActionType> {
  readonly type = AuthActionType.SetError;
  constructor(public payload: { error: string }) {}
}

export type AuthAction =
  | CheckEpycToken
  | Register
  | RegisterSuccess
  | RegisterError
  | RequestAccessToken
  | RequestAccessTokenSuccess
  | RequestAccessTokenError
  | ResetRegistration
  | SetError;
