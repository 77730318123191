import { routerMiddleware } from 'connected-react-router';
import { createHashHistory, History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from './rootEpic';
import createRootReducer, { AppState } from './rootReducer';

export const history: History = createHashHistory({
  hashType: 'slash',
});

export function configureStore(): Store {
  const epicMiddleware = createEpicMiddleware();

  const logger = createLogger({
    collapsed: true,
    diff: true,
  });

  const plainMapper = () => next => action => next(Object.assign({}, action));

  const store: Store<AppState> = createStore(
    createRootReducer(history),
    compose(applyMiddleware(plainMapper, epicMiddleware, routerMiddleware(history), logger)),
  );

  epicMiddleware.run(rootEpic);

  return store;
}
