import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { Action, combineReducers, Reducer } from 'redux';

import authReducer, { AuthState } from '../auth/_store/reducer';
import chooseTypeReducer, { ChooseTypeState } from '../choose-type/_store/reducer';
import examinationReducer, { ExaminationState } from '../examination/_store/reducer';

export interface AppState {
  auth: AuthState;
  examination: ExaminationState;
  router: RouterState;
  type: ChooseTypeState;
}

function appReducer(history: History): Reducer {
  return combineReducers<AppState>({
    auth: authReducer,
    examination: examinationReducer,
    router: connectRouter(history),
    type: chooseTypeReducer,
  });
}

export default (history: History) => (state: AppState, action: Action) => {
  return appReducer(history)(state, action);
};
