import { HttpClient } from '../../_http';
import { AccessTokenResponse } from '../_models/AccessTokenResponse';
import { Auth } from '../_models/Auth';

export function requestAccessToken(): Promise<AccessTokenResponse> {
  return HttpClient.get<AccessTokenResponse>('auth/gettoken', null);
}

export function register(body: Auth, accessToken: string): Promise<void> {
  const urlencodedBody = body as unknown as Record<string, string>;

  return HttpClient.post<void>('proefexamen/register', new URLSearchParams(urlencodedBody), {
    Authorization: `Basic ${accessToken}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  });
}

export function checkEpycToken(token: string): Promise<string> {
  if (!token) return new Promise((_, rej) => rej());

  const decodedBaseToken = atob(token);

  function validateEmail() {
    const EMAIL_REGEX =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return EMAIL_REGEX.test(decodedBaseToken);
  }

  return new Promise((resolve, reject) => {
    if (validateEmail()) {
      resolve(decodedBaseToken);
    } else reject();
  });
}
