import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';

import { useToggle } from '../_hooks';
import { Button, Timer } from '../_shared';
import ImageWithFallback from '../_shared/ImageWithFallback';
import { authSelectors, examinationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { AnswerOptionStatus, isCorrectAnswer, isEpyc, RENDER_DELAY } from '../_utils/questionHelpers';
import Countdown from '../countdown/Countdown';
import { Answer } from '../examination/_models';
import AnswerOption from '../examination/answer-option/AnswerOption';
import ImageCloseUp from '../examination/modal/imgcloseup/ImageCloseUp';
import StopExaminationModal from '../examination/modal/stopexaminationmodal/StopExaminationModal';
import OutOfTime from './modal/outoftime/OutOfTime';

import './example-question.scss';

const ExampleQuestion: FC = () => {
  const [selectedAnswer, setSelectedAnswer] = useState<Answer>(null);
  const [showCountdown, setShowCountdown] = useToggle(false);
  const [isImageCloseOpen, showImageCloseUp] = useToggle(false);
  const [isStopExaminationOpen, setStopExamination] = useToggle(false);
  const [isOutOfTime, setOutOfTime] = useToggle(false);
  const question = useSelector(examinationSelectors.exampleQuestion);
  const hasSelectedWrongAnswer = selectedAnswer && !isCorrectAnswer(question, selectedAnswer);
  const token = useSelector(authSelectors.token);

  useEffect(() => {
    setTimeout(() => setOutOfTime(true), question?.time * 1000 * RENDER_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function answerToStatus(answer: Answer): AnswerOptionStatus {
    if (!selectedAnswer || selectedAnswer.answer_id !== answer.answer_id) return AnswerOptionStatus.Unselected;
    if (selectedAnswer.correct_ind) return AnswerOptionStatus.Correct;
    if (!selectedAnswer.correct_ind) return AnswerOptionStatus.Wrong;
  }

  function showCountDown(): void {
    setShowCountdown(true);
  }

  if (!question) return <Redirect to="/" />;

  return (
    <>
      {isStopExaminationOpen && <StopExaminationModal closeModal={() => setStopExamination(false)} />}
      {isImageCloseOpen && <ImageCloseUp onClose={() => showImageCloseUp(false)} question={question} />}
      {hasSelectedWrongAnswer && <div className="wrong-wrapper">{translations.getLabel('QUESTIONS.WRONG_ANSWER')}</div>}
      {isOutOfTime && !showCountdown && <OutOfTime onClose={() => setOutOfTime(false)} />}
      {showCountdown && <Countdown />}
      <Timer question={question} />
      <div className={classnames('question-wrapper', { 'is-epyc': isEpyc(token) })}>
        <div className="pinch-zoom-wrapper">
          <ImageWithFallback
            alt={translations.getLabel('MODAL.IMAGE_ALT')}
            onClick={() => showImageCloseUp(true)}
            src={question.img_url}
          />
        </div>
        <div className="answers-wrapper">
          <span className="info example">{translations.getLabel('QUESTIONS.INFO').toUpperCase()}</span>
          <h2>{question.question}</h2>
          {question.answers.map(answer => (
            <AnswerOption
              answer={answer}
              key={answer.answer_id}
              onSelect={() => setSelectedAnswer(answer)}
              status={answerToStatus(answer)}
            />
          ))}
          <div className="actions-wrapper">
            <Button asText onClick={() => setStopExamination(true)}>
              {translations.getLabel('BUTTONS.STOP')}
            </Button>
            <Button disabled={!selectedAnswer || hasSelectedWrongAnswer} onClick={showCountDown} theme="primary">
              {translations.getLabel('BUTTONS.DONE')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExampleQuestion;
