import { I18n } from 'i18n-js';

import { config } from '../config';
import fr from './fr.json';
import { Language } from './Language';
import nl from './nl.json';

const i18n = new I18n({
  fr,
  nl,
});

i18n.enableFallback = true;
const locale = Language.getLocale();
i18n.locale = locale === 'fr' ? 'fr' : 'nl';
// i18n.missingTranslation = translation => `[[${translation}]]`;

const getLabel = (key: string, inserts = {}): string => {
  if (config.environment() !== 'production' && i18n.t(key, inserts) === '') {
    return `[[${key}]]`;
  }
  return i18n.t(key, inserts);
};

export default {
  I18n: i18n,
  getLabel,
};
