import React, { FC } from 'react';

import { Button, Icon } from '../../../_shared';
import ImageWithFallback from '../../../_shared/ImageWithFallback';
import { translations } from '../../../_translations';
import { Question } from '../../_models';

import './imgcloseup.scss';

type Props = {
  onClose: () => void;
  question: Question;
};

const ImageCloseUp: FC<Props> = ({ onClose, question }) => {
  return (
    <div className="img-modal">
      <Icon className="close-icon" name="SvgClose" onClick={onClose} />
      <ImageWithFallback alt={translations.getLabel('MODAL.IMAGE_ALT')} src={question.img_url} />
      <Button className="close reversed" onClick={onClose}>
        {translations.getLabel('BUTTONS.CLOSE')}
      </Button>
    </div>
  );
};

export default ImageCloseUp;
