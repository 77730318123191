export type Environment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = process.env.NODE_ENV;
function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

function apiHost(): string {
  return process.env.REACT_APP_API_HOST;
}

function examinationApiHost(): string {
  return process.env.REACT_APP_EXAMINATION_API_HOST;
}

function apiKey(): string {
  return process.env.REACT_APP_API_KEY;
}

function isLoggedInForDev(): boolean {
  return process.env.REACT_APP_IS_LOGGED_IN_FOR_DEV === 'true';
}

function environment(): Environment {
  return nodeEnv as Environment;
}

function sentryDsn(): string {
  return process.env.REACT_APP_SENTRY_DSN;
}

const config = { apiHost, apiKey, environment, examinationApiHost, isLoggedInForDev, sentryDsn };

export { config };
