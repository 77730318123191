import { Answer, Category, Question } from '../examination/_models';

const DEFAULT_READING_TIME = 23;
const READING_TIME_PER_WORD = 2;

export const getAmountOfQuestions = (categories: Category[]) => {
  return categories.map((cat: Category) => parseInt(cat.limit)).reduce((acc, val) => acc + val);
};

export const RENDER_DELAY = 1.025;

export enum AnswerOptionStatus {
  Correct = 'CORRECT',
  Selected = 'SELECTED',
  Unselected = 'UNSELECTED',
  Wrong = 'WRONG',
}

export function calculateTime(sentence: string): number {
  // count how many words the sentence includes
  const wordCount = sentence.split(' ').length;

  // calculate if the sentence is too long for the default time
  const isLongerSentence = wordCount * READING_TIME_PER_WORD > DEFAULT_READING_TIME;

  // if sentence is too long, calculate the extra time the user gets
  if (isLongerSentence) {
    return wordCount * READING_TIME_PER_WORD;
  }
  // return default time
  return DEFAULT_READING_TIME;
}

export function selectExampleQuestion(questions: Question[]): Question {
  const question = questions[Math.floor(Math.random() * questions.length)];
  return {
    ...question,
    time: calculateTime(question.question),
  };
}

export function selectExaminationQuestions(
  categories: Category[],
  questions: Question[],
): { exampleQuestion: Question; questions: Question[] } {
  const exampleQuestion = selectExampleQuestion(questions);
  return {
    exampleQuestion,
    questions: categories
      .map((category: Category) => {
        const maxAmountPerCategory = parseInt(category.limit);

        // filtered questions by category_id and exclude example question
        const filteredQuestions = questions.filter(question => {
          if (question.category_id !== category.category_id) return false;
          if (question.question_id === exampleQuestion.question_id) return false;
          return true;
        });

        const addedTimeForQuestion = filteredQuestions.map(question => ({
          ...question,
          time: calculateTime(question.question),
        }));

        // select ‘maxAmountPerCategory’ random questions
        return addedTimeForQuestion
          .sort(() => {
            return 0.5 - Math.random();
          })
          .slice(0, maxAmountPerCategory);
      })
      //flatten array
      .reduce((acc, currentValue) => [...acc, ...currentValue], []),
  };
}

export const isCorrectAnswer = (question: Question, answer: Answer): boolean => {
  const correctAnswer = question.answers.find(answer => answer.correct_ind);

  return answer.answer_id === correctAnswer.answer_id;
};

export function selectNextQuestion(addedQuestionId: string | null, questions: Question[]): Question {
  const currentQuestion = questions.find(q => q.question_id === addedQuestionId);
  const currentQuestionId = questions.indexOf(currentQuestion);

  return questions[currentQuestionId + 1];
}

export function amountOfCorrectAnswers(result: Record<string, Answer>): number {
  if (!result) return 0;
  return Object.values(result).filter(item => item?.correct_ind).length;
}

export function hasPassedExamination(result: Record<string, Answer>, amountOfCorrectAnswersRequired: number): boolean {
  return amountOfCorrectAnswers(result) >= amountOfCorrectAnswersRequired;
}

export function isMajorMistake(question: Question): boolean {
  return question?.weight_category === 'MajorOffence';
}

export function shuffle(array: Answer[]) {
  return array.sort(() => Math.random() - 0.5);
}

export function isEpyc(isEpyc: string) {
  if (isEpyc) return true;
  return false;
}

export function isRenderedInIframe() {
  return window.location !== window.parent.location;
}
