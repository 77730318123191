import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useToggle } from '../../_hooks';
import { authSelectors } from '../../_store/selectors';
import { calculateTime, isEpyc } from '../../_utils/questionHelpers';
import { Question } from '../../examination/_models';

import './timer.scss';

type Props = {
  question: Question;
};

const Timer: FC<Props> = ({ question }) => {
  const [value] = useToggle(false);
  const [isTimerStarted, startCounter] = useState<boolean>(value);
  const limitTime = calculateTime(question?.question || '');
  const token = useSelector(authSelectors.token);

  useEffect(() => {
    startCounter(false);
    setTimeout(() => startCounter(true), 300);
  }, [question]);

  return (
    <div
      className={classnames('filler', { 'is-epyc': isEpyc(token), progression: isTimerStarted })}
      style={{ transition: `width ${isTimerStarted ? limitTime : 0}s linear` }}
    />
  );
};

export default Timer;
