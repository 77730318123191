export type Locale = 'fr' | 'nl';
export const availableLanguages = ['fr', 'nl', 'de', 'en'];

export class Language {
  static setLocale(locale: Locale): void {
    if (Language.getLocale() === locale) return;
    window.localStorage.setItem('locale', locale);
    // Reload the window to make app re-render
    window.location.reload();
  }

  static getLocale(): Locale {
    //defaults to 'nl'
    return (window.localStorage.getItem('locale') as Locale) || 'nl';
  }
}
