import axios from 'axios';

import { ExamType } from '../../choose-type/_models/ExamType';
import { config } from '../../config';
import { Examination } from '../_models';

export async function fetchExercises(type: ExamType) {
  //TODO: add param for -nl || -fr ?
  return axios.get<Examination>(`${config.examinationApiHost()}exam/${type.toLowerCase()}/exercises-nl.json`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
}
