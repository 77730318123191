import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useWindowSize } from '../_hooks';
import { Button, Icon } from '../_shared';
import { examinationActions } from '../_store/actions';
import { chooseTypeSelectors, examinationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { startScreenInfo } from '../_utils/examHelpers';
import { isRenderedInIframe } from '../_utils/questionHelpers';

import './startscreen.scss';

const StartScreen: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasntAcceptedCookiebot = (window as any).Cookiebot?.consent?.stamp === '0';
  const width = useWindowSize();
  const dispatch = useDispatch();
  const examType = useSelector(chooseTypeSelectors.examType);
  const amountOfQuestions = useSelector(examinationSelectors.amountOfQuestions);
  const amountOfCorrectAnswersRequired = useSelector(examinationSelectors.amountOfCorrectAnswersRequired);
  const { type, literals } = startScreenInfo[examType] || {};

  function handleClick(): void {
    dispatch(new examinationActions.StartExampleQuestion());
  }

  if (!type || !literals) return <Redirect to="/" />;

  return (
    <div className={`startscreen-wrapper exam-type-${type}`}>
      <div className="literals-wrapper">
        <h1>{translations.getLabel(`START.${type}.TITLE`)}</h1>
        <br />
        <p dangerouslySetInnerHTML={{ __html: translations.getLabel(`START.${type}.PARAGRAPH`) }} />
        <br />
        <ul>
          {literals.map(value => (
            <li key={value}>
              {translations.getLabel(`START.${type}.LITERAL_${value}`, { amountOfCorrectAnswersRequired, amountOfQuestions })}
            </li>
          ))}
        </ul>
        {/* TODO: Check if !hasRenderedLogin condition needs replacement */}
        {!hasntAcceptedCookiebot && width < 450 && isRenderedInIframe() && (
          <div className="cookie" dangerouslySetInnerHTML={{ __html: translations.getLabel('LOGIN.COOKIE') }} />
        )}
      </div>
      <Button onClick={handleClick} theme="primary">
        <p>{translations.getLabel('BUTTONS.START_EXAM')}</p>
        <Icon name="SvgChevronRight" />
      </Button>
    </div>
  );
};

export default StartScreen;
