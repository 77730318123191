import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useWindowSize } from '../../../_hooks';
import { Button } from '../../../_shared';
import { examinationActions } from '../../../_store/actions';
import { translations } from '../../../_translations';
import { Language } from '../../../_translations/Language';
import { isDesktopSize } from '../../../_utils/breakpointHelpers';

import './stopexaminationmodal.scss';

type Props = {
  closeModal: () => void;
};

const StopExaminationModal: FC<Props> = ({ closeModal }) => {
  const language = Language.getLocale();
  const width = useWindowSize();
  const dispatch = useDispatch();

  return (
    <div className="stop-modal">
      <div className="info-wrapper">
        <h2>{translations.getLabel('QUESTIONS.CLOSE_MODAL.TITLE')}</h2>
        <p>{translations.getLabel('QUESTIONS.CLOSE_MODAL.CONTENT')}</p>
      </div>
      <div className="buttons">
        <Button asText onClick={closeModal} theme={!isDesktopSize(width) ? 'primary' : 'negative'}>
          {translations.getLabel('BUTTONS.CANCEL')}
        </Button>
        <div onClick={() => dispatch(new examinationActions.StopExamination())}>
          <Button className="close" href={`/start?language=${language}`}>
            {translations.getLabel('BUTTONS.CONFIRM_STOP')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StopExaminationModal;
